import React, {useEffect} from 'react';
import { BrowserRouter } from "react-router-dom"; /* Agregamos la libreria para las rutas */
import Cuerpo from './Cuerpo';
// import Navbar from './Navbar';
import Header from './Header';
import Login from './Login';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import { validarTokenAccion } from '../redux/informacion_principal';
import AlertWebtrade from './AlertWebtrade';

function IndexApp() {
	const data = useSelector(store => store);
    const cookies = new Cookies();
    const dispatch = useDispatch();

    useEffect(() => {
        let token = cookies.get('userToken', { path: '/'});
        console.log('valida el token:', token);
        if(token){
            dispatch(validarTokenAccion())
        }
    }, []);

    let token = data.informacionUsuario.token;

	return (
		<React.Fragment>
            {(token)?
                (<BrowserRouter>
                    <Header />
                    <div className="container-fluid">
                        <div className="row flex-grow-sm-1 flex-grow-0">
                            {/* <Navbar /> */}
                            <Cuerpo />
                        </div>
                    </div>
                </BrowserRouter>):(<div className='login-component'><Login /></div>)
            }
            <AlertWebtrade />
		</React.Fragment>
		);
}

export default IndexApp;
