import { callSymbols, callBars } from '../../../../../config/helper'

// Make requests to CryptoCompare API
export async function makeApiRequest(path) {
    try {
        // const url = `https://api.polygon.io/v2/aggs/ticker/AAPL/range/4/week/2021-07-22/2021-07-22?adjusted=true&sort=asc&limit=120&apiKey=d4GeupwKpDXgbLqBmaOjxXAfkDlBRD25`
        const url = `https://min-api.cryptocompare.com/${path}`
        const info = await fetch(url)
            .then(response => response.json())  // convertir a json
            .then(json => {return json})    //imprimir los datos en la consola
            .catch(err => console.log('Solicitud fallida', err)); // Capturar errores
        const json = {
            Data:{
                ABCC:{
                isActive:true,
                isTopTier:true,
                pairs:{
                    AAPL:{
                        0:"ETH",
                        1:"BTC",
                        2:"USDT"
                    }
                },
                    TSLA:{
                        0:"USDT"
                    }
                }
            }
        }
        
        return info
    } catch(error) {
        throw new Error(`CryptoCompare request error: ${error.status}`);
    }
}

// Generate a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
    const short = `${fromSymbol}/${toSymbol}`;
    return {
        short,
        full: `${exchange}:${short}`,
    };
}

export function parseFullSymbol(fullSymbol) {
    const match = fullSymbol.split(':')
    return { exchange: match[0], fromSymbol: match[1], toSymbol: 'USD' };
}


// Make requests to CryptoCompare API
export async function allSymbolsMcv() {
    try {
        // const info = {
        //     Data:{
        //         ABCC:{
        //             isActive:true,
        //             isTopTier:true,
        //             pairs:{
        //                 AAPL:['USD'],
        //                 TSLA:['USD','EUR'],
        //                 NFLX:['USD','CNH','GBP'],
        //             }
        //         },
        //         DBCC:{
        //             isActive:true,
        //             isTopTier:true,
        //             pairs:{
        //                 AAPL:['USD'],
        //                 TSLA:['USD','EUR'],
        //                 NFLX:['USD','CNH','GBP'],
        //             }
        //         }
        //     }
        // }
        const info = await callSymbols();
        return info

    } catch(error) {
        throw new Error(`CryptoCompare request error: ${error.status}`);
    }
}

// Make requests to CryptoCompare API
export async function apiBarMcv(symbol,exchange,carga,resolution) {
    try {
        const info = await callBars({symbol,exchange,carga,resolution});
        if (info.message === 'Successful') {
            let data = info.body.data;
            return data
        }
        
    } catch(error) {
        throw new Error(`apiBarMcv request error: ${error.status}`);
    }
}