import * as React from 'react';
import './index.css';
import { widget } from '../../../../charting_library';
import Datafeed from './api/'




// diccionario de props --> https://github.com/tradingview/charting_library/wiki/Widget-Constructor#drawings_access
export class TVChartContainer extends React.PureComponent {

	static defaultProps = {
		// symbol: 'ABCC:AAPL/USD',
		// symbol: 'AAPL',
		interval: 'D',
		// datafeedUrl: data,
		// datafeedUrl: new window.Datafeeds.UDFCompatibleDatafeed(data),
		datafeedUrl: 'https://demo-feed-data.tradingview.com',
		libraryPath: '/charting_library/',
		chartsStorageUrl: 'https://saveload.tradingview.com',
		chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		userId: 'public_user_id',
		fullscreen: false,
		autosize: true,
		studiesOverrides: {},
	};

	tvWidget = null;

	constructor(props) {
		super(props);

		this.ref = React.createRef();
	}

	componentDidMount() {
		const widgetOptions = {
			symbol: this.props.symbol,
			// BEWARE: no trailing slash is expected in feed URL
			// datafeed: new window.Datafeeds.UDFCompatibleDatafeed(this.props.datafeedUrl),
			interval: this.props.interval,
			datafeed: Datafeed,
			container: this.ref.current,
			library_path: this.props.libraryPath,
			// debug: true,
			locale: 'es',
			theme: this.props.theme,
			disabled_features: ['use_localstorage_for_settings','header_compare','header_undo_redo','header_settings','edit_buttons_in_legend','header_undo_redof','header_saveload','chart_template_storage'],
			charts_storage_url: this.props.chartsStorageUrl,
			charts_storage_api_version: this.props.chartsStorageApiVersion,
			client_id: this.props.clientId,
			user_id: this.props.userId,
			fullscreen: this.props.fullscreen,
			autosize: this.props.autosize,
			studies_overrides: this.props.studiesOverrides,
			information: this.props.information,
			stopLoss: this.props.stopLoss,
			takeProfit: this.props.takeProfit,
		};

		const tvWidget = new widget(widgetOptions);
		this.tvWidget = tvWidget;

		tvWidget.onChartReady(() => {
			tvWidget.headerReady().then(() => {
				// const button = tvWidget.createButton();
				// button.setAttribute('title', 'Click to show a notification popup');
				// button.classList.add('apply-common-tooltip');
				// button.addEventListener('click', () => tvWidget.showNoticeDialog({
				// 	title: 'Notification',
				// 	body: 'TradingView Charting Library API works correctly',
				// 	callback: () => {
				// 		console.log('Noticed!');
				// 		this.forceUpdate();
				// 	},
				// }));
				// button.innerHTML = 'Check API';

				//desplegamos el Take Profin
				if(this.props.takeProfit > 0 ){
					tvWidget.chart()
						.createOrderLine()
						.setText("Take Profit")
						.setLineLength(3)
						.setLineStyle(2)
						.setQuantity("")
						.setPrice( this.props.takeProfit)
						.setEditable()
						.setLineColor("rgb(255, 0, 0)")
						.setBodyBorderColor("rgb(255, 0, 0)")
						.setBodyTextColor("rgb(241, 150, 150)");}
				
				//desplegamos el Stop Loss
				if(this.props.stopLoss > 0 ){
					tvWidget.chart()
						.createOrderLine()
						.setText("Stop Loss")
						.setLineLength(3)
						.setLineStyle(2)
						.setQuantity("")
						.setPrice( this.props.stopLoss)
						.setEditable()
						.setLineColor("rgb(255, 0, 0)")
						.setBodyBorderColor("rgb(255, 0, 0)")
						.setBodyTextColor("rgb(241, 150, 150)");}
			});
		});
	}

	componentWillUnmount() {
		if (this.tvWidget !== null) {
			this.tvWidget.remove();
			this.tvWidget = null;
		}
	}

	render() {
		return (
			<div
				ref={ this.ref }
				className={ 'TVChartContainer' }
			/>
		);
	}
}
