import * as React from 'react';
import './App.css';
import './styles/navbar.css';
import IndexApp from  './components/IndexApp';

import { Provider } from 'react-redux';
import generateStore from './redux/store'

function App() {

	const store = generateStore()

	return (
		<Provider store={store}>
			<div className={ 'App' } id="App">
				<IndexApp />
			</div>
		</Provider>
		);
}

export default App;
