// const linkBackend = `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}`
const linkBackend = process.env.REACT_APP_BACKEND_IP
import { GET, POST } from './conect';

export async function login(data){return POST(`${linkBackend}/auth/webtrade`, data)};

export async function logout(){return GET(`${linkBackend}/auth/logout`)};

export async function getInfo(){return GET(`${linkBackend}/users/information-account`)};

// export async function createAccountLive(){return GET(`${linkBackend}/users/create-live`)};

export async function getInfoComplete(){return GET(`${linkBackend}/users/information-complete`)};

export async function getGeneralInformation(){return GET(`${linkBackend}/information`)};

export async function updateGeneralInformation(data){return POST(`${linkBackend}/users/update-information`,data)};

// export async function changePasswordApi(data){return POST(`${linkBackend}/users/newpaswword`, data)};

export async function getAccount(data){ return POST(`${linkBackend}/users/get-account`, data)};

export async function investInformation(data){return POST(`${linkBackend}/users/invest`,data)};

export async function tradeInformation(data){return POST(`${linkBackend}/invest/tradeInformation`,data)};

export async function newOperation(data){return POST(`${linkBackend}/invest/newOperation`,data)};

export async function closeOperation(data){return POST(`${linkBackend}/invest/closeOperation`,data)};

// export async function newWithdrawals(data){return POST(`${linkBackend}/operations/withdrawals`,data)};

// export async function newDeposit(data){return POST(`${linkBackend}/operations/deposits`,data)};

// APIS PARA LA MT4 Y FUNCIONAMIENTO DE LA GRAFICO DE TRADINGVIEW

export async function callSymbols(){return GET(`${linkBackend}/tradeview/callSymbols`)};

export async function callBars(data){return POST(`${linkBackend}/tradeview/callBars`,data)};