import React from 'react';

function Loading({show}) {

    const showLoader = (v) => {
        if(v){
            return <img src="/img/loader.gif" style={{maxWidth:'60px'}} alt="" />
        }
    }

    return (
        <React.Fragment>
            <div>
                <center>
                    {showLoader(show)}  
                </center>
            </div>
        </React.Fragment>
    );
}

export default Loading;