const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: '50%',
    bottom: 'auto',
    marginRight: '-40%',
    transform: 'translate(-50%, -50%)',
    background: "black",
    borderRadius: '10px',
  },
  '@media (max-width: 940px)': {
    content: {
      marginRight: '-90%',
    },
  },
};
  
  export default modalStyles;