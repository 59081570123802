import React from 'react';
import {useSelector} from 'react-redux';

function Reports() {

  let granTotal = 0;
  const fechaActual = new Date().toLocaleDateString()
	const store = useSelector(store => store)
    const data = store.informacionUsuario
    const movements = store.investmentInfoAccount.movements
    const dataUser = store.investmentInfoAccount.dataUser.data

    // Create styles
  const styles = {
    page: {
        flexDirection: "column",
        borderRadius:"0px"
    },
    header: {
        fontSize: 15,
        fontWeight: 700,  
        backgroundColor:"grey",
        padding:10,
        marginBottom:25,
        flexDirection: "row",
        textAlign:"center",
        borderRadius:"0px"
    },
    text:{
      width: "35%",
    },
    image: {
        width:"40%" 
    },
    title: {
        textAlign:"center",
        fontWeight: 800,  
        fontSize:25
    },
    table: {
        flexDirection: "row",
        flexWrap: "wrap",
        margin:"5px",
        borderRadius:"0px"
    },
    tableHeader: {
        title:{
            flexDirection: "row",
        textAlign: "center",
        backgroundColor: "grey",
        color:"white",
        fontWeight: 800,  
        paddingBottom:"5px",
        paddingTop:"5px",
        borderRadius:"0px",
        marginBottom:"10px"
        },
        column:{
            width: "10%",
            height:"25px",
        }
    },
    colum: {
      paddingTop:"5px",
      fontSize: 12,
      width: "10%",
      height:"40px",
      borderBottomWidth:1,
      borderBottomStyle:'solid',
      borderBottomColor:'black',
      textAlign:"center",
      borderRadius:"0px"
    },
    tableBody: {
      flexDirection: "row",
      alignItems: "center"
    }
  }                                                             ;

  const limpiarFecha = (date) => {
    return date.split('.')[0].replace('-', '/').replace('-', '/').replace('T', ' ').replace('Z', '')
  }

  return (
    <div style={{textAlign:'right',borderLine:'none', backgroundColor:'white'}}>
      <div style={styles.page}>
        <div style={styles.header} className='row'>
          {/* <div  className='col-4'> Account: {data.cuenta} </div > */}
          <div  className='col-6'> Name: {dataUser.firstName} {dataUser.lastName} </div >
          <div  className='col-6'> Date: { fechaActual } </div >
        </div>
        <div style={styles.title}>
          <div>Historial de Movimientos</div>
        </div>
        <div style={styles.table}>
            <div  style={styles.tableHeader.title} className='row'>
              <div style={styles.tableHeader.column} className='col-1'>Ticket</div >
              <div style={styles.tableHeader.column} className='col-2'>Open Time</div >
              <div style={styles.tableHeader.column} className='col-1'>Type</div >
              <div style={styles.tableHeader.column} className='col-1'>Size</div >
              <div style={styles.tableHeader.column} className='col-1'>Item</div >
              <div style={styles.tableHeader.column} className='col-1'>Price</div >
              <div style={styles.tableHeader.column} className='col-1'>S / L</div >
              <div style={styles.tableHeader.column} className='col-1'>T / P</div >
              <div style={styles.tableHeader.column} className='col-1'>Price</div >
              <div style={styles.tableHeader.column} className='col-2'>Profit</div >
            </div>
            { movements.map((row, index) => {
                granTotal += row.amount;
                return (<div style={styles.tableBody} className='row' key={index}>
                  <div style={styles.colum} className='col-1'>{ row.code }</div>
                  <div style={styles.colum} className='col-2'>{ limpiarFecha(row.createdAt) }</div>
                  {row.type === 'trade' ? <div style={styles.colum} className='col-2'>{ row.type } ({ row.dataOperation.operation })</div>:<div style={styles.colum} >{ row.type }</div>}
                  {row.type === 'trade' ? <div style={styles.colum}>{ row.dataOperation.volumen }</div>:<div style={{...styles.colum, width:"60%"}}> { row.comment } </div>}
                  {row.type === 'trade' ? <div style={styles.colum}>{ row.dataOperation.symbol }</div>:null}
                  {row.type === 'trade' ? <div style={styles.colum}>{ row.dataOperation.price }</div>:null}
                  {row.type === 'trade' ? <div style={styles.colum}>{ row.dataOperation.stop_loss }</div>:null}
                  {row.type === 'trade' ? <div style={styles.colum}>{ row.dataOperation.take_profit }</div>:null}
                  {row.type === 'trade' ? <div style={styles.colum}>{ row.dataOperation.actualPrice }</div>:null}
                  <div style={styles.colum} >{ row.amount.toFixed(6) }</div>
                </div >)               
              }) }
              <div style={{...styles.colum, textAlign:'right',width: "100%"}} >Gran Total: { granTotal ? granTotal.toFixed(6):0 }</div>
        </div>
      </div>
    </div>
  );

}

export default Reports;