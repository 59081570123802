import Cookies from 'universal-cookie';

const cookies = new Cookies();

export async function GET(link){
    try {

        const token = cookies.get('userToken', { path: '/'});

        const response = await  fetch(link, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'apikey':token
            }})
            
        return response.json();

    } catch(error) {
        throw new Error(`CryptoCompare request error: ${error.status}`);
    }
}

export async function POST(link, body){
    try {

        const token = cookies.get('userToken', { path: '/'});

        const response = await  fetch(link, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'apikey':token
            },
            body: JSON.stringify(body)    
        })
            
        return response.json();

    } catch(error) {
        throw new Error(`CryptoCompare request error: ${error.status}`);
    }
}