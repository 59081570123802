import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loginAccion } from '../redux/informacion_principal';
import InputsPassword from './InputsPassword';
import { useTranslation } from "react-i18next";
import '../styles/login.css'
import Cookies from 'universal-cookie';
import { showAlertAccion, closeAlertAccion } from '../redux/alert_webtrade';

function Login() {

    /*const socket = new WebSocket('ws://localhost:8080');
    
    socket.onopen = () => {
        console.log('Conexión establecida en cliente');
        socket.send('Hola servidor desde el cliente');
    };
    socket.onmessage = (event) => {
        //console.log('Mensaje recibido:', event.data);
        console.log('Mensaje recibido: en cliente1');
    };
    socket.onmessage = (mensaje) => {
        //console.log('Mensaje recibido:', event.data);
        console.log('onmessage1: en cliente: ',mensaje.data);
        //socket.send('envio mensaje');
    };
    socket.onclose = () => {
        console.log('Conexión cerrada en cliente');
    };*/
  


    const cookies = new Cookies();

    useEffect(() => {
        let token = cookies.get('userToken', { path: '/'});
        if (!token){
            cookies.set("userToken", '', {path: "/"});
        }
    }, []);

    const [usuario, setUsuario] = useState('');
    const [password, setPassword] = useState('');
	const { t } = useTranslation();

	const dispatch = useDispatch();

    const openAlert = (message,typeAlert) =>{
        dispatch(showAlertAccion({message,typeAlert}));
    }

    const closeAlert = () =>{
        dispatch(closeAlertAccion());
    }

    const logIn = async () => {
        let infoLogin = {usuario,password}
        openAlert(t('login.login'),'light');
        dispatch(await loginAccion(infoLogin))
        .then(response => {
            setTimeout(() => {
                closeAlert();
                if(response == 'error' || !response){
                    console.log('Error al iniciar: ',response)     
                    activarAlerta()
                }
            },5000)
        });
    }

    const activarAlerta = () => {
        const alert = document.querySelector('#alert');
        alert.classList.add('show');
    }    

    

    return (
        <React.Fragment><center>
            <div className='login'>
                <div className="row" >
                    <div className="col-xs-12">
                        <img className="logologin" src="/img/logo-login.png" alt="" />
                    </div>
                </div>
                <div className="row" >
                    <div className="col-xs-12 col-sm-2 col-md-3 col-lg-4"></div>
                    <div className="col-xs-12 col-sm-8 col-md-6 col-lg-4 textlabellogin">
				        <label htmlFor="user" className="form-label  mb-0 mt-2 labelloginuser"> {t('login.user')} </label>
                        <input 
                            type="text" 
                            className="form-control inputuserlogin gen-input-text"
                            placeholder="Usuario"
                            onChange={e => setUsuario(e.target.value)} 
                            name="user" /* el name tiene que tener el mismo nombre de la variable */  
                            />
                    </div>
                    <div className="col-xs-12 col-sm-2 col-md-3 col-lg-4"></div>
                </div>
                <div className="row" >
                    <div className="col-xs-12 col-sm-2 col-md-3 col-lg-4"></div>
                    <div className="col-xs-12 col-sm-8 col-md-6 col-lg-4">
                        <InputsPassword onChangeFuntion={(datos) => setPassword(datos)} 
                            inputId="password" labelText={t('login.password')} />
                    </div>
                    <div className="col-xs-12 col-sm-2 col-md-3 col-lg-4"></div>
                </div>
                <div className="row" >
                    <div className="col-xs-12 col-sm-2 col-md-3 col-lg-4"></div>
                    <div className="col-xs-12 col-sm-8 col-md-6 col-lg-4">
                        <button className="btn btnlogin" onClick={()=>logIn()}> {t('login.enter')} </button>
                    </div>
                    <div className="col-xs-12 col-sm-2 col-md-3 col-lg-4"></div>
                </div>
            </div>
            </center>
            <div id="alert" className="alert alert-danger fade" role="alert">
                <strong>{t('login.error')}</strong>{t('login.error_message')}
            </div>
        </React.Fragment>
    );
}

export default Login;