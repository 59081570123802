import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

function AccountHistory({changeSendInfo, selectedItem}) {

	const { t } = useTranslation();
    const [data, setData] = useState([]);
	const store = useSelector(store => store)

    useEffect(() => {
        obtenerDatos()
    }, []);

    const obtenerDatos = () => {
        // Datos de Ejemplo
        
        // let arrayTemporal = [{
            //     Order:146423,
            //     Time:'02-01-2023 11:15:32',
            //     Type:'sell',
            //     Size:0,
            //     Symbol:'GOLD',
            //     Price:3,
            //     stopLoss:0.0,
            //     takeProfit:0.0,
            //     Swap:0.65,
            // }]
            
        // aqui comienza verdaderamente la funcion.

        const arrayInicial = store.investmentInfoAccount.trades
        let arrayData = [];

        arrayInicial.forEach(function(elemento, indice, array){

            if( !elemento.status ) {

                // let actualPrice = 50;
                // let profit = elemento.Type == 'sell'? (elemento.Price - actualPrice):(actualPrice - elemento.Price);
    
                arrayData.push({
                    order:elemento.order,
                    time:elemento.createdAt,
                    volumen:elemento.volumen,
                    type:elemento.operation,
                    size:elemento.size,
                    symbol:elemento.symbol,
                    price:elemento.price,
                    stopLoss:elemento.stop_loss,
                    takeProfit:elemento.take_profit,
                    // actualPrice:actualPrice,
                    // swap:elemento.Swap,
                    // profit:profit.toFixed(2)
                })
                
            }
        });
            


        setData(arrayData);
    }

		return (
            <div className="container mt-3">
                <table className="table table-striped gen-fontwhite">
                    <thead>
                        <tr>
                            <th> {t('mt4.order')} </th>
                            <th> {t('mt4.time')} </th>
                            <th> {t('mt4.type')} </th>
                            <th> {t('mt4.size')} </th>
                            <th> {t('mt4.symbol')} </th>
                            <th> {t('mt4.price')} </th>
                            <th> {t('mt4.sl')} </th>
                            <th> {t('mt4.tp')} </th>
                            {/* <th> {t('mt4.actual_price')} </th> */}
                            {/* <th> {t('mt4.swap')} </th> */}
                            {/* <th> {t('mt4.profit')} </th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(item => (
                            <tr style={{'cursor':'pointer'}} className={item.order == selectedItem ? 'trade-selected':'trade-unselected gen-fontwhite'} key={item.order} onClick={() => changeSendInfo({'id':item.order,'symbol':item.symbol,'stopLoss':item.stopLoss,'takeProfit':item.takeProfit})} >
                                <td>{ item.order }</td>
                                <td>{ item.time }</td>
                                <td>{ item.type }</td>
                                <td>{ item.volumen }</td>
                                <td>{ item.symbol }</td>
                                <td>{ item.price }</td>
                                <td>{ item.stopLoss }</td>
                                <td>{ item.takeProfit }</td>
                                {/* <td>{ item.actualPrice }</td> */}
                                {/* <td>{ item.swap }</td> */}
                                {/* <td>{ item.profit }</td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
		);
}

export default AccountHistory;