import React, {useState} from 'react';
import { Link, Routes, Route} from "react-router-dom"; /* Agregamos la libreria para las rutas */
import MovementsHistory from './MovementsHistory';
import AccountHistory from './AccountHistory';
import Trade from './Trade';
import { useTranslation } from "react-i18next";

function Details({changeSimbol, useAlert}) {
	const { t } = useTranslation();
    const [option, setOption] = useState('Trade')
    const [selected, setSelected] = React.useState(0);


    const styles = {
        'margin': '25px 0px',
        'width': '100%',
        'background': '#000'
    };

    const changeOption = (select) => {
        setOption(select);
    }

    const sendInfo = (data) => {
        setSelected(data.id);
        changeSimbol({'symbol':data.symbol,'stopLoss':data.stopLoss,'takeProfit':data.takeProfit})
    }

    const openAlert = (message) => {
        useAlert(message);
    }

    return (
    <div style={styles}>
            <ul className="nav nav-tabs backgroundgen">
                <li className="nav-item">
                    <Link to="Trade" onClick={() => changeOption('Trade')} className={`nav-link ${ option == 'Trade' ? 'active':'backgroundgen gen-fontwhite' }`}> {t('mt4.trade')} </Link>
                </li>
                {/* <li className="nav-item">
                    <Link to="accountHistory" onClick={() => changeOption('accountHistory')} className={`nav-link  ${ option == 'accountHistory' ? 'active':'backgroundgen gen-fontwhite' }`}> {t('mt4.account_history')} </Link>
                </li> */}
                <li className="nav-item">
                    <Link to="movementsHistory" onClick={() => changeOption('movementsHistory')} className={`nav-link  ${ option == 'movementsHistory' ? 'active':'backgroundgen gen-fontwhite' }`}> {t('mt4.movements_history')} </Link>
                </li>
            </ul>
            <div className="controlate backgroundgen">
                <Routes className='controlate'>
                    <Route path="/" element={<Trade changeSendInfo={(data) => sendInfo(data)}  selectedItem={selected} useAlert={(message)=> openAlert(message)}/>} />
                    <Route path="/Trade/*" element={<Trade changeSendInfo={(data) => sendInfo(data)}  selectedItem={selected} useAlert={(message)=> openAlert(message)}/>}/>
                    <Route path="/accountHistory/*" element={<AccountHistory   changeSendInfo={(data) => sendInfo(data)} selectedItem={selected}/>} />
                    <Route path="/movementsHistory/*" element={<MovementsHistory />} />
                </Routes>
            </div>
            <hr className="backgroundgen" />
    </div>
    );
}

export default Details;