// constantes
const dataInicial = {
    message:'',
    typeAlert:'',
    state:'fade',
    time:5,
    theme:'primary'
}

//types
const SHOW_ALERT = 'SHOW_ALERT'
const CLOSE_ALERT = 'CLOSE_ALERT'

//
export default function alertsWebtrade(state = dataInicial, action){
    switch(action.type){
        case SHOW_ALERT:
            return {
                message:action.message,
                typeAlert:action.typeAlert,
                state:'show',
                time:action.time,
                theme:action.theme}
        case CLOSE_ALERT:
            return {
                message:'',
                typeAlert:'',
                state:'fade',
                time:5,
                theme:'primary'
            }
        default:
            return state
    }
}

// acciones

export const showAlertAccion = (data) => async (dispatch, getState) => {
    try {
        let properties = {
            type:SHOW_ALERT,
            message:data.message,
            typeAlert:data.typeAlert,
        }

        if(data.hasOwnProperty('time')){
            properties.push({time:data.time})
        }
        if(data.hasOwnProperty('theme')){
            properties.push({theme:data.theme})
        }

        dispatch(properties)
        
    } catch (error) {
        console.log('Error en aplicación:',error);
    }
}

export const closeAlertAccion = () => async (dispatch, getState) => {
    try {
        dispatch({type:CLOSE_ALERT})
    } catch (error) {
        console.log('Error en aplicación:',error);
    }
}