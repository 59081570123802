import React from 'react';
import { useSelector } from 'react-redux';

function AlertWebtrade() {
    
    const store = useSelector(store => store);
    const alert = store.alertRedux


    return (
        <React.Fragment>
            <div className={`alert alert-webtrade alert-${alert.typeAlert} ${ alert.state }`} id="alert-webtrade" role="alert"> 
            { alert.typeAlert === 'light' ? <img src="/img/loader.gif" style={{maxWidth:'60px'}} alt="" />:('') }<br/>
            { alert.message } </div>
            <div className={`alertBackground ${ alert.state }`}></div>
        </React.Fragment>
    );
}
export default AlertWebtrade;