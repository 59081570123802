// import { parseFullSymbol } from './helper.js';
const channelToSubscription = new Map();


//const serverUrl = 'ws://localhost:3001/websocket';
const serverUrl = process.env.REACT_APP_SOCKETLOCAL+"/websocket";

const socket = new WebSocket(serverUrl);

// Evento que se ejecuta cuando la conexión se ha establecido
socket.onopen = () => {
    console.log('Conexión establecida con el servidor WebSocket');
  };

  socket.onmessage = (event) => {
    const datos = JSON.parse(event.data)
    // console.log(datos);
    socketTradingView(datos);
    // if(datos.event == 'subscribe'){
    //     socketTradingView(datos);
    // }else if(datos.event == 'compraventa'){
    //     socketOperation(datos)
    // }
  }

const socketOperation = (event) => {

}

const socketTradingView = (datos) => {
    // console.log('[socket tradeview] in', datos);
    // console.log('evento de socket', event)
    // const datos = JSON.parse(event.data)
    // console.log('[socket tradeview] tradeTimeStr', datos.value.t);
    const tradeTimeStr = datos.value.t
    const tradePriceStr = datos.value.vw
    const tradeVolumenStr = datos.value.v
    const tradeCloseStr = datos.value.o
    const tradeOpenStr = datos.value.c
    const tradehighStr = datos.value.h
    const tradelowStr = datos.value.l
    let tradePrice = parseFloat(tradePriceStr);
    let tradeVolumen = parseFloat(tradeVolumenStr);
    let tradeOpen = parseFloat(tradeOpenStr);
    let tradeClose = parseFloat(tradeCloseStr);
    let tradeHigh = parseFloat(tradehighStr);
    let tradeLow = parseFloat(tradelowStr);
    const tradeTime = parseInt(tradeTimeStr);
    const subscriptionItem = channelToSubscription.get(datos.channel);
    // const subscriptionItem = channelToSubscription.get('X:BTCUSD');
    
    if (subscriptionItem === undefined) {
        // console.log('if si el subscriptionItem es indefinido');
        return;
    }
    const lastDailyBar = subscriptionItem.lastDailyBar;
    const nowTime = datos.value.t
    const lastTime = lastDailyBar.time
    // const nextDailyBarTime = getNextDailyBarTime(lastTime,nowTime,subscriptionItem.resolution);
    let bar;
    if(nowTime > lastTime){
        bar = {
            time: nowTime,
            open: tradeOpen,
            high: tradeHigh,
            low: tradeLow,
            close: tradeClose,
            volume: tradeVolumen,
        };
        // console.log('[socket] Generate new bar', bar);
    }else{
        bar = {
            ...lastDailyBar,
            high: Math.max(lastDailyBar.high, tradePrice),
            low: Math.min(lastDailyBar.low, tradePrice),
            volume: tradeVolumen,
            close: tradePrice,
        };
        // console.log('[socket] Generate update bar', bar);
    }

    subscriptionItem.lastDailyBar = bar;
    // send data to every subscriber of that symbol
    subscriptionItem.handlers.forEach(handler => handler.callback(bar));
};

export function subscribeOnStream(
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback,
    lastDailyBar
    ) {
    const channelString = symbolInfo.name;
    const handler = {
        id: subscribeUID,
        callback: onRealtimeCallback,
    };
    let subscriptionItem = channelToSubscription.get(channelString);
    // let subscriptionItem = channelToSubscription.get('X:BTCUSD');
    if (subscriptionItem) {
        // already subscribed to the channel, use the existing subscription
        subscriptionItem.handlers.push(handler);
        return;
    }
    
    const message = JSON.stringify({
        channel: channelString,
        event: 'subscribe',
        t: 's',
        tipo: 'currencies',
    });
    // const message = JSON.stringify({
    //     channel: 'X:BTCUSD',
    //     event: 'subscribe',
    //     t: 's',
    //     tipo: 'currencies',
    // });
    
    subscriptionItem = {
        message,
        subscribeUID,
        resolution,
        lastDailyBar,
        handlers: [handler],
    };
    
    channelToSubscription.set(channelString, subscriptionItem);
    // channelToSubscription.set('X:BTCUSD', subscriptionItem);
    
    socket.send(message);
}

export function subscribeOnOperation( account, symbol ) {

    const message = JSON.stringify({
        event: 'compraventa',
        account,
        symbol
    });
    
    socket.send(message);
}

export function unsubscribeFromStream(subscriberUID) {
    
    // find a subscription with id === subscriberUID
    for (const channelString of channelToSubscription.keys()) {
        const subscriptionItem = channelToSubscription.get(channelString);
        const handlerIndex = subscriptionItem.handlers
        .findIndex(handler => handler.id === subscriberUID);
        
        if (handlerIndex !== -1) {
            // remove from handlers
            subscriptionItem.handlers.splice(handlerIndex, 1);
            
            if (subscriptionItem.handlers.length === 0) {
                // unsubscribe from the channel, if it was the last handler
                // console.log('[unsubscribeBars]: Unsubscribe from streaming. Channel:', channelString);
                // socket.emit('SubRemove', { subs: [channelString] });
                channelToSubscription.delete(channelString);
                break;
            }
        }
    }
}

function getNextDailyBarTime(barTime,newTime,timeMode) {
    return newTime >= barTime ? true:false;
}