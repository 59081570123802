import React, { useEffect } from 'react';
import { Routes, Route} from "react-router-dom"; /* Agregamos la libreria para las rutas */
import Graficas from '../page/mt4/Graficas';
import Report from './Reports';
import { useSelector} from 'react-redux'
import { closeAlertAccion } from '../redux/alert_webtrade';
import { useDispatch } from 'react-redux';

function Cuerpo() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(closeAlertAccion());
    }, []);

		return (
            <React.Fragment>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/socket.io/1.7.2/socket.io.js"></script>
                <main className="col overflow-auto h-100">
                    <div className="bg-light border rounded-3 p-3 backgroundgen" id="body">
                        <Routes>
                            <Route path="/*" element={<Graficas />} />
                            <Route path="/report" element={<Report />} />
                        </Routes>
                    </div>
                </main>
            </React.Fragment>
		);
}

export default Cuerpo;