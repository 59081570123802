import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import TemplatePdf from '../../../components/TemplatePdf';
import { Link } from "react-router-dom"; /* Agregamos la libreria para las rutas */

function MovementsHistory({changeSendInfo, selectedItem}) {

	const { t } = useTranslation();
    // const [data, setData] = useState([]);
	const store = useSelector(store => store)

    // useEffect(() => {
    //     obtenerDatos()
    // }, []);

    // const obtenerDatos = () => {
    //     const arrayData = store.investmentInfoAccount.movements

    //     arrayData.forEach(function(elemento, indice, array){

    //         arrayData.push({
    //             order:elemento.Order,
    //             time:elemento.Time,
    //             type:elemento.Type,
    //             size:elemento.Size,
    //             symbol:elemento.Symbol,
    //             price:elemento.Price,
    //             stopLoss:elemento.stopLoss,
    //             takeProfit:elemento.takeProfit,
    //             actualPrice:actualPrice,
    //             swap:elemento.Swap,
    //             profit:profit.toFixed(2)
    //             })

    //     });
            


    //     setData(arrayData);
    // }

		return (
            <div className="container mt-3">
                {/* <TemplatePdf movements={store.investmentInfoAccount.movements} data={store.informacionUsuario} dataUser={store.investmentInfoAccount.dataUser.data} />    */}
                <div style={{textAlign:'right',borderLine:'none'}}>
                    <Link to="../report"  style={{color:'white'}} className={`btn btn-outline-dark`}>  Descargar Historial </Link>
                </div>
                <table className="table table-striped gen-fontwhite">
                    <thead>
                        <tr>
                            <th> {t('mt4.order')} </th>
                            <th> {t('mt4.type')} </th>
                            <th> {t('mt4.symbol')} </th>
                            <th> {t('mt4.time')} </th>
                            <th> {t('mt4.expire_time')} </th>
                            <th> {t('mt4.price')} </th>
                        </tr>
                    </thead>
                    <tbody>
                        {store.investmentInfoAccount.movements.map(item => (
                            <tr className='trade-unselected gen-fontwhite' key={item.code}>
                                <td>{ item.code }</td>
                                <td>{ item.type }</td>
                                <td>{ item.dataOperation ? item.dataOperation.symbol:null }</td>
                                <td>{ item.createdAt }</td>
                                <td>{ item.expire_date }</td>
                                <td>{ item.amount.toFixed(6) }</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
		);
}

export default MovementsHistory;