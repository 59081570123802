import {validarToken, login, logout, getInfo, getAccount} from '../config/helper'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

// constantes
const dataInicial = {
    cuenta:'',
    token: '',
    role:'',
}


//types
const GET_LOGIN = 'GET_LOGIN'
const GET_LOGOUT = 'GET_LOGOUT'
const GET_VALIDAR_TOKEN = 'GET_VALIDAR_TOKEN'
const GET_TIPO_USUARIO = 'GET_TIPO_USUARIO'

//
export default function informacionPrincipal(state = dataInicial, action){

    switch(action.type){
        case GET_LOGIN:
            return {...state, token:action.token, cuenta:action.cuenta, role:action.role}
        case GET_VALIDAR_TOKEN:
            return {...state, token:action.token, cuenta:action.cuenta, role:action.role}
        case GET_LOGOUT:
            return {dataInicial}
        default:
            return state
        }
    }

// acciones

export const loginAccion = (cliente) => async (dispatch, getState) => {
    try {

        //Enviar datos y recibir la respuesta
        let data = {'user':cliente.usuario,'password':cliente.password};
        const logIn = await login(data);
        const {token,role} = logIn.body;

        if(logIn.message === 'Successful'){

            cookies.set("userToken", token, {path: "/"});
            dispatch({
                type:GET_LOGIN,
                cuenta:cliente.usuario,
                token,
                role,
            });

        }else{
            return('error')
        }
        // login(data).then(function(rest){
        //     const jwToken = rest.body.token;

        //     cookies.set("userToken", jwToken, {path: "/"});
            
        //     getInfo(jwToken)
        //     .then(function(info){
        //         if (rest.message == 'Successful') {
        //             const items = info.body
        //             dispatch({
        //                 type:GET_LOGIN,
        //                 cuenta:items.account,
        //                 token: jwToken,
        //                 user:items.user,
        //                 country:items.country,
        //             });
        //         } else{
        //             return('error')
        //         }
        //     })
        // });

    } catch (error){
        console.log(error)
    }
}

export const validarTokenAccion = () => async (dispatch, getState) => {
    try {

        const data = await getInfo();
        console.log('recibiendo data: ',data);
        if (data.message === 'Successful') {
            // console.log('adentro del dispach')
            const {account, token, role} = data.body;
            dispatch({
                type:GET_VALIDAR_TOKEN,
                cuenta:account,
                token,
                role,
            });
        } else{
            cookies.remove('userToken', { path: '/'});
        }
        // getInfo()
        // .then( async function(data){
        //     if (data.message == 'Successful') {
        //         let accounts = data.account;
        //         const { body } = await getAccount()
        //         accounts = body.account

        //         dispatch({
        //             type:GET_VALIDAR_TOKEN,
        //             cuenta: accounts,
        //             tipoCuenta: data.body.typeAccout,
        //             token: token,
        //             user: data.body.user,
        //             country: data.body.country,
        //         });
        //     } else{
        //         cookies.remove('userToken', { path: '/'});
        //     }
        // })

    } catch (error){
        console.log(error)
    }
}

export const obtenerUsuarioAccion = (type) => async (dispatch, getState) => {
    try {
        cookies.set("typeAccout", type, {path: "/"});

        dispatch({
            type:GET_TIPO_USUARIO,
            tipoCuenta:type
        })
    } catch (error){
        console.log(error)
    }
}

export const logoutAccion = () => async (dispatch, getState) => {
    
    
    try {
        const { message } = await logout();
        if (message === 'Successful'){
            cookies.remove('userToken', { path: '/'});
            dispatch({
                type:GET_LOGOUT,
            });
        }
        
    } catch (error){
        console.log(error)
    }
}