import { apiBarMcv } from './helper.js';
import { subscribeOnStream, unsubscribeFromStream } from './streaming'
import Cookies from 'universal-cookie';

const lastBarsCache = new Map();
const firstBarsCache = new Map();
const firstBarsCache1D = new Map();
const historyBarsCache = new Map();
let timeSelected = '';

let symbolCache = JSON.parse(sessionStorage.getItem("symbolList"));

const configurationData = {
    // supported_resolutions: ['1','H','24H','1D', '1W', '1M'],
    supported_resolutions: ['1','H','12H','1D', '1W'],
    exchanges: [
        {
            value: 'FOREX',
            name: 'FOREX',
            desc: 'FOREX',
        },
        {
            value: 'STOCKS',
            name: 'STOCKS',
            desc: 'STOCKS',
        },
        {
            value: '',
            name: 'All',
            desc: 'ALL',
        }
    ],
    symbols_types: [
        // {
        //     name: 'ABCC',

        //     // `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
        //     value: 'ABCC',
        // },
        // ...
    ],
};

async function loadSymbols() {
    if (!symbolCache) {
      symbolCache = JSON.parse(sessionStorage.getItem("symbolList"));
    }
  }

export default {
    onReady: (callback) => {
        setTimeout(() => callback(configurationData));
    },
    searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
        const userInputLower = userInput.toLowerCase();
        const exchangeLower = exchange.toLowerCase();
        
        if(!symbolCache){await loadSymbols()};
        const newSymbols = symbolCache.filter((symbol) => {
          const isExchangeValid = exchangeLower === "" || symbol.exchange.toLowerCase() === exchangeLower;
          const isFullSymbolContainsInput = symbol.full_name.toLowerCase().includes(userInputLower);
          return isExchangeValid && isFullSymbolContainsInput;
        }).slice(0, 1000);
        
        onResultReadyCallback(newSymbols);
      },
    resolveSymbol: async (
        symbolName,
        onSymbolResolvedCallback,
        onResolveErrorCallback
    ) => {
        if(!symbolCache){await loadSymbols()};

        const symbolItem = symbolCache.find(({ full_name }) => full_name === symbolName);
        if (!symbolItem) {
            onResolveErrorCallback('cannot resolve symbol');
            return;
        }

        const symbolInfo = {
            ticker: symbolItem.full_name,
            name: symbolItem.symbol,
            description: symbolItem.description,
            type: symbolItem.type,
            session: '24x7',
            timezone: 'Etc/UTC',
            exchange: symbolItem.exchange,
            minmov: 1,
            pricescale: 1000000,
            has_intraday: true,
            has_no_volume: true,
            has_weekly_and_monthly: false,
            supported_resolutions: configurationData.supported_resolutions,
            volume_precision: 2,
            data_status: 'streaming',
        };

        onSymbolResolvedCallback(symbolInfo);
    },


	getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
        let { from, to, firstDataRequest } = periodParams;
        const cookies = new Cookies();
        cookies.set("parsedSymbol", symbolInfo.name, {path: "/"});
        let bars = [];
        try {

            const selectFirstBarsCache = (type) => {
                if(type === 'D'){
                    return firstBarsCache1D.get(symbolInfo.full_name) ? firstBarsCache1D.get(symbolInfo.full_name).time : 999999999999999999;
                }else if(type === 'M'){
                    return firstBarsCache.get(symbolInfo.full_name) ? firstBarsCache.get(symbolInfo.full_name).time : 999999999999999999;
                } 
            }

            const fechaInicial = resolution === '1D' ? selectFirstBarsCache('D'):selectFirstBarsCache('M');
            

            firstBarsCache.get(symbolInfo.full_name) ? firstBarsCache.get(symbolInfo.full_name).time : 999999999999999999;
            const data = await apiBarMcv(symbolInfo.name,symbolInfo.exchange,fechaInicial,resolution);
            const historial =  historyBarsCache.get(symbolInfo.full_name) ? historyBarsCache.get(symbolInfo.full_name) : [];
            // if (data.Response && data.Response === 'Error' || data.Data.length === 0) {
            if (data.length === 0) {
                // "noData" should be set if there is no data in the requested period.
                onHistoryCallback([], { noData: true });
                return;
            }

            const history = timeSelected === resolution ? historial:[]
            bars = resolution === '1D' ? crearArrayDay(data,history):crearArray(data,history);
            
            // from = 1
            // to = to * 1000
            timeSelected = resolution;

            lastBarsCache.set(symbolInfo.full_name, {...bars[bars.length - 1],});
            if (firstDataRequest) {
                resolution === '1D' ? firstBarsCache1D.set(symbolInfo.full_name, {...bars[0],}):firstBarsCache.set(symbolInfo.full_name, {...bars[0],});
                historyBarsCache.set(symbolInfo.full_name, bars);
			}
            onHistoryCallback(bars, { noData: true });
            // setTimeout(function(){
    } catch (error) {
            onErrorCallback(error);
        }
    },
    subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
        subscribeOnStream(
            symbolInfo,
            resolution,
            onRealtimeCallback,
            subscribeUID,
            onResetCacheNeededCallback,
            lastBarsCache.get(symbolInfo.full_name)
        );
    },
    unsubscribeBars: (subscriberUID) => {
        unsubscribeFromStream(subscriberUID);
    },
};

const validarV = (v) => parseFloat((v?.$numberDecimal || "0").toString());

const crearArray = (data, array) => {
    const barsData = data.map((bar) => ({
        time: parseFloat(bar.t.$numberDecimal.toString()),
        low: parseFloat(bar.l.$numberDecimal.toString()),
        high: parseFloat(bar.h.$numberDecimal.toString()),
        open: parseFloat(bar.o.$numberDecimal.toString()),
        close: parseFloat(bar.c.$numberDecimal.toString()),
        v: validarV(bar.v),
    }));

    const combinedBars = barsData.reverse().concat(array); // Combina los datos de data y array

    return combinedBars; // Invierte el orden si es necesario
};

const crearArrayDay = (data, array) => {
    const barsData = data.map((bar) => ({
        time: parseFloat(bar.t.toString()),
        low: parseFloat(bar.l.toString()),
        high: parseFloat(bar.h.toString()),
        open: parseFloat(bar.o.toString()),
        close: parseFloat(bar.c.toString()),
        v: parseFloat(bar.v.toString()),
    }));

    const combinedBars = barsData.reverse().concat(array); // Combina los datos de data y array

    return combinedBars; // Invierte el orden si es necesario
};