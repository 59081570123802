import React from 'react';
import '../styles/navbar.css';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'

function InfoCuenta({sizeCantidad, sizeTiulo}) {
	
	const { t } = useTranslation();
    const data = useSelector(store => store)

	const dataAccount = data.investmentInfoAccount

	const title = {
		fontSize: `${sizeTiulo}vw`,
		marginBottom: -5
	}

	const cantidad = {
		fontSize: `${sizeCantidad}vw`,
		marginBottom: -5
	}

	const cantidadStyle = () => {

		if(screen.width < 600){
			let newSizeCantidad = sizeCantidad * 5
			
			return {
				//fontSize: `${newSizeCantidad}vw`,
				fontSize: '20px',
				marginBottom: -10
			}
		}else if(screen.width>600 && screen.width<=768){
			return {
				fontSize: '25px',
				marginBottom: -10
			}
		}else if(screen.width>768 && screen.width<=992){
			return {
				fontSize: '18px',
				marginBottom: -10
			}
		}else if(screen.width>992 && screen.width<=1200){
			return {
				fontSize: '23px',
				marginBottom: -10
			}
		}else if(screen.width>1200){
			return {
				fontSize: '24px',
				marginBottom: -10
			}
		}else{
			return {
				fontSize: `${sizeCantidad}vw`,
				marginBottom: -5
			}
		}
	}

	const titleStyle = () => {

		if(screen.width <= 600){
			let newSizeTiulo = sizeTiulo * 2
			return {
				fontSize: `${newSizeTiulo}vw`,
				marginBottom: 15
			}		
		}else if(screen.width>600 && screen.width<=768){
			return {
				fontSize: '15px',
				marginBottom: 15
			}
		}else if(screen.width>768 && screen.width<=992){
			return {
				fontSize: '15px',
				marginBottom: 15
			}
		}else if(screen.width>992 && screen.width<=1200){
			return {
				fontSize: '17px',
				marginBottom: 15
			}
		}else if(screen.width>1200){
			return {
				fontSize: '18px',
				marginBottom: 15
			}
		}else{
			return {
				fontSize: `${sizeTiulo}vw`,
				marginBottom: -5
			}
		}
	}

	return (
		<React.Fragment>
			<div className="row">
				<div className="col-6 col-md-4 col-lg-2 gen-fontwhite">
					<center><p style={cantidadStyle()}>$ { dataAccount.balance.toFixed(2) }</p></center>
					<center><p style={titleStyle()}>{t("infoAccount.Balance")}</p></center>
				</div>
				<div className="col-6 col-md-4 col-lg-2 gen-fontwhite">
					<center><p style={cantidadStyle()}>$ { dataAccount.credit.toFixed(2) }</p></center>
					<center><p style={titleStyle()}>{t("infoAccount.Credit")}</p></center>
				</div>
				<div className="col-6 col-md-4 col-lg-2 gen-fontwhite">
				<center><p style={cantidadStyle()}>$ { dataAccount.equity.toFixed(2) }</p></center>
				<center><p style={titleStyle()}>{t("infoAccount.Equity")}</p></center>
				</div>
				<div className="col-6 col-md-4 col-lg-2 gen-fontwhite">
				<center><p style={cantidadStyle()}>{ (dataAccount.margin).toFixed(2) }</p></center>
				<center><p style={titleStyle()}>{t("infoAccount.Margin")}</p></center>
				</div>
				<div className="col-6 col-md-4 col-lg-2 gen-fontwhite">
				<center><p style={cantidadStyle()}>{ dataAccount.freeMargin.toFixed(6) }</p></center>
				<center><p style={titleStyle()}>{t("infoAccount.free_Margin")}</p></center>
				</div>
				<div className="col-6 col-md-4 col-lg-2 gen-fontwhite">
				<center><p style={cantidadStyle()}>{ (dataAccount.marginLevel ? dataAccount.marginLevel:0).toFixed(2) }%</p></center>
				<center><p style={titleStyle()}>{t("infoAccount.Level")}</p></center>
				</div>
			</div>
		</React.Fragment>
	);
}

export default InfoCuenta;