import { investInformation } from '../config/helper';

// constantes
const dataInicial = {
    balance:0.00,
    credit:0.00,
    equity:0.00,
    margin:0.00,
    freeMargin:0.00,
    marginLevel:0.00,
    totalDepositado:0.00,
    dataUser:[],
    movements:[],
    trades:[]
}

//types
const GET_INFORMACION = 'GET_ACTUALIZAR_INFORMACION'
const GET_CARGAR_INFO_ACCOUNT = 'GET_CARGAR_INFO_ACCOUNT'
const GET_ACTUALIZAR_INFORMACION = 'GET_ACTUALIZAR_INFORMACION'
const GET_AGREGAR_CREDITO = 'GET_AGREGAR_CREDITO'

//
export default function investmentAccount(state = dataInicial, action){
    switch(action.type){
        case GET_INFORMACION:
            return {...state,
                type:GET_INFORMACION,
                balance:action.balance,
                credit:action.credit,
                equity:action.equity,
                margin:action.margin,
                freeMargin:action.freeMargin,
                marginLevel:action.marginLevel,
                totalDepositado:action.totalDepositado,
                dataUser:action.dataUser,
                movements:action.movements,
                trades:action.trades}
        case GET_CARGAR_INFO_ACCOUNT:
            return {...state,
                balance:action.balance,
                credit:action.credit,
                equity:action.equity,
                margin:action.margin,
                freeMargin:action.freeMargin,
                marginLevel:action.marginLevel,
                trades:action.trades}
        case GET_ACTUALIZAR_INFORMACION:
            return {...state,
                equity:action.equity,
                margin:action.margin,
                freeMargin:action.freeMargin,
                marginLevel:action.marginLevel,
                trades:action.trades}
        default:
            return state
    }
}

// acciones

export const AccountInfoAccion = (data) => async (dispatch, getState) => {
    try {
        const { dataUser, dataAccount, historyMovement, historyInvest } = data;
        const { balance, credit, equity, margin, freeMargin, totalDeposit, marginLevel } = dataAccount;
        
        console.log('dataUser',dataUser)

        dispatch({
            type:GET_INFORMACION,
            balance,
            credit,
            equity,
            margin,
            freeMargin,
            marginLevel,
            totalDepositado:totalDeposit,
            dataUser,
            movements:historyMovement,
            trades:historyInvest
            })
        
    } catch (error) {
        console.log('Error en aplicación:',error);
    }
}

// export const getAccountInfoAccion = () => async (dispatch, getState) => {
//     try {

//         //obtener datos de las inversiones y formar el array
//         let arrayTemporal = [{
//             order:156423,
//             time:'02-01-2023 11:15:32',
//             type:'sell',
//             size:0,
//             symbol:'GOLD',
//             price:3,
//             stopLoss:0.0,
//             takeProfit:21,
//             swap:0.65,
//         },{
//             order:19853,
//             time:'09-01-2023 17:54:02',
//             type:'buy',
//             size:0.02,
//             symbol:'AAPL',
//             price:35.21,
//             stopLoss:120,
//             takeProfit:169,
//             swap:0.65,
//         }]


//         //obtener datos de la cuenta
//         let arrayAccountTemporal = {
//             balance:1000,
//             credit:500,
//             equity:1350,
//             margin:500,
//             freeMargin:1000,
//             marginLevel:2.7
//         }

//         //Calcular los datos de la cuenta
//         let arrayData = [];

//         arrayTemporal.forEach(function(elemento, indice, array){
//             let actualPrice = 50;
//             let profit = elemento.Type == 'sell'? (elemento.price - actualPrice):(actualPrice - elemento.price);
//             arrayData.push({
//                 order:elemento.order,
//                 time:elemento.time,
//                 type:elemento.type,
//                 size:elemento.size,
//                 symbol:elemento.symbol,
//                 price:elemento.price,
//                 stopLoss:elemento.stopLoss,
//                 takeProfit:elemento.takeProfit,
//                 actualPrice:actualPrice,
//                 swap:elemento.swap,
//                 profit:profit
//             })
//         });

//         const rest = {
//             type:GET_CARGAR_INFO_ACCOUNT,
//             balance:arrayAccountTemporal.balance,
//             credit:arrayAccountTemporal.credit,
//             equity:arrayAccountTemporal.equity,
//             margin:arrayAccountTemporal.margin,
//             freeMargin:arrayAccountTemporal.freeMargin,
//             marginLevel:arrayAccountTemporal.marginLevel,
//             trades:arrayData
//         }

//         //Enviar y actualizar los datos al estado.
//         dispatch(rest)

//     } catch (error){
//         console.log(error)
//     }
// }

// export const actualizarDatosAccion = () => (dispatch, getState) => {
//     try {

//         //obtener el listado de las inversiones.
//         const inversiones = getState().investmentInfoAccount.trades;

//         let balance = getState().investmentInfoAccount.balance;
//         let credito = getState().investmentInfoAccount.credit;

//         //obtener datos de la cuenta
//         let totEquity = 0;
//         let totMargin = 0;
//         let freeMargin = 0;
//         let totMarginLevel = 0;
//         // let sumSwap = 0;
//         let sumPrice= 0;
//         var sumProfit = 0;
//         // let sumpComicion = 0;
//         let nuevoArrayData = []

//         //Calcular los datos de la cuenta
//         inversiones.forEach(function(elemento, indice, array){
//             let precioActual = getRandomInt(53);
//             let profit = elemento.type == 'sell'? (elemento.price - precioActual):(precioActual - elemento.price);
//             let newSwap = ((0.2 * 1)/365) * precioActual //swap = ((tasa de swap*1) / 365 días) * el valor nominal de tu posición
            
//             nuevoArrayData.push({
//                 order:elemento.order,
//                 time:elemento.time,
//                 type:elemento.type,
//                 size:elemento.size,
//                 symbol:elemento.symbol,
//                 price:elemento.price,
//                 stopLoss:elemento.stopLoss,
//                 takeProfit:elemento.takeProfit,
//                 actualPrice:precioActual,
//                 swap:newSwap,
//                 profit:profit
//             })

//             // sumSwap = sumSwap + newSwap
//             sumProfit = sumProfit + profit
//             // let comicion = profit * 0.05
//             // sumpComicion = sumpComicion + comicion
//             sumPrice = sumPrice + elemento.price
//         });

//         //asignar los datos finales
//         // totEquity = getState.balance + getState.credit + sumProfit + sumSwap + sumpComicion; //Equidad = Saldo + Crédito + Pérdida/ganancia + Swap + Comisión
//         totEquity = ( balance + credito ) + sumProfit
//         totMargin = sumPrice
//         freeMargin = ( balance + credito ) - totMargin
//         totMarginLevel = (totEquity/totMargin) * 100;
        
//         dispatch({
//             type:GET_ACTUALIZAR_INFORMACION,
//             equity:totEquity,
//             margin:totMargin,
//             freeMargin:freeMargin,
//             marginLevel:totMarginLevel,
//             trades:nuevoArrayData
//             })

//     } catch (error){
//         console.log(error)
//     }
// }

export const agregarCreditoAccion = (credit) => (dispatch, getState) => {
    try {

        //Obtener datos de crédito actuales.
        newCredit = credit + getState.credit

        //Enviar dato.
        dispatch({
            type:GET_AGREGAR_CREDITO,
            credit:newCredit,
            })

    } catch (error){
        console.log(error)
    }
}

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
};